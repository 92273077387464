import {
    Get_Upsell_Start,
    Get_Upsell_data,
    Get_Frontsale_data,
    Get_Upsell_end


} from '../types'
import { message } from 'antd';
import baseUrl from '../../config.json'




export const get_AllfrontSale = (body) => async (dispatch) => {
    try {
        dispatch({
            type: Get_Upsell_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/order/GetAllFrontSales`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const res = await response.json()
            dispatch({
                type: Get_Frontsale_data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_Upsell_end,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_Upsell_end,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}



export const get_AllUpsell = (body) => async (dispatch) => {
    try {
        dispatch({
            type: Get_Upsell_Start,
            payload: true,
            loading: true,
        });
        const response = await fetch(`${baseUrl.baseUrl}/order/GetAllUpsells`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const res = await response.json()
            dispatch({
                type: Get_Upsell_data,
                payload: [{ res }],
                loading: false,
            });
        } else {
            const res = await response.json()
            dispatch({
                type: Get_Upsell_end,
                payload: [{ res }],
                loading: false,
            });
        }
    }
    catch (error) {
        dispatch({
            type: Get_Upsell_end,
            payload: false,
            loading: false,
        });
        console.log(error)
    }

}

