import { combineReducers, compose, createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import All_Order from "./reducers/allCompany/index";
import TaskReducer from "./reducers/TaskReducer/index";
import CompanyReducer from "./reducers/allCompany/index";
import AllSchedule from "./reducers/schedule/index";
import Red_LeadMonthAndYear from "./reducers/Leads/index"
import Red_ManualUpsell from "./reducers/ManualUpsale/index"
import Red_AllUpsell from "./reducers/AllUsales";


const reducers = combineReducers({
  All_Order,
  TaskReducer,
  CompanyReducer,
  AllSchedule,
  Red_LeadMonthAndYear,
  Red_ManualUpsell,
  Red_AllUpsell
});

const composeEnhancers = window.REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(ReduxThunk)));
export default store;