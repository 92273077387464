import {
    Get_Upsell_Start,
    Get_Upsell_end,
    Get_Frontsale_data,
    Get_Upsell_data,
} from '../../actions/types'


const initState = {
    AllUpsell: [],
    Allfrontsale: [],
    loading: false,
}

const Red_AllUpsell = (state = initState, action) => {
    switch (action.type) {
        case Get_Upsell_Start:
            return {
                ...state,
                loading: action.payload,
            };
        case Get_Frontsale_data:
            return {
                ...state,
                Allfrontsale: action.payload,
            };
        case Get_Upsell_data:
            return {
                ...state,
                AllUpsell: action.payload,
            };

        case Get_Upsell_end:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default Red_AllUpsell