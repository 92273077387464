import {
    Get_ManualUpsell_Data,
    Get_ManualUpsell_Start,
    Get_ManualUpsell_Leads_Data,
    Get_ManualUpsell_Package_Data,
    Get_ManualUpsell_End,
} from '../../actions/types'


const initState = {
    data: [],
    getLeads: [],
    getPackage: [],
    dataSingle: [],
    loading: false,
}

const Red_ManualUpsell = (state = initState, action) => {
    switch (action.type) {
        case Get_ManualUpsell_Start:
            return {
                ...state,
                loading: action.payload,
            };
        case Get_ManualUpsell_Package_Data:
            return {
                ...state,
                getPackage: action.payload,
            };
        case Get_ManualUpsell_Data:
            return {
                ...state,
                data: action.payload,
            };
        case Get_ManualUpsell_Leads_Data:
            return {
                ...state,
                getLeads: action.payload,
            };
        case Get_ManualUpsell_End:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default Red_ManualUpsell