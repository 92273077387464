import { message } from 'antd';
import baseUrl from '../../config.json'
import secureLocalStorage from 'react-secure-storage';


export const getCompanyList = () => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/company/getAllCompanies`, {
            method: "GET",
            headers: {
                'accessToken': 'Bareer ' + secureLocalStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}
export const InsertComapny = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/company/InsertCompany`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + secureLocalStorage.getItem("access_token"),
            },
            body: body
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else if (res.message == "file already exist") {
            message.error('File already exist')
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}
export const deleteCompany = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/company/DeleteCompany`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + secureLocalStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                company_code: body
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}
export const companyDetail = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/company/getCompanyDetailsByCompanyCode`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + secureLocalStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                company_code: body
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}
export const updateCompany = (body) => async () => {
    try {
        const response = await fetch(`${baseUrl?.baseUrl}/company/UpdateCompanyDetails`, {
            method: "POST",
            headers: {
                'accessToken': 'Bareer ' + secureLocalStorage.getItem("access_token"),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                company_name: body?.company_name,
                company_email: body?.company_email,
                company_address: body?.company_address,
                company_phone_number: body?.company_phone_number,
                company_code: body?.company_code
            })
        });
        const res = await response.json()
        if (res?.success == "success") {
            return res
        }
        else {
            message.error('Somthing went wrong')
        }
    }
    catch (error) {
        console.log(error)
    }
}