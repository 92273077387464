import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PrimaryButton } from "../../pages/component/Button"
import * as Leads_Actions from "../../store/actions/Leads/index";
import { MdDeleteOutline } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { FormInput, FormSelect } from '../../pages/component/formInput'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Space, Table, Tag, Tooltip } from "antd";
import * as yup from "yup";
import { Popconfirm } from 'antd';
import { message } from 'antd';
import baseUrl from '../config.json'
import secureLocalStorage from "react-secure-storage";

const LeadByMonthYear = ({ Red_LeadMonthAndYear, get_LeadMonthlyAndYearly_Data }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [mode, setMode] = useState("read");
    const [loading, setLoading] = useState(false);
    var get_access_token = secureLocalStorage.getItem("access_token");
    const [isCode, setCode] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const LeadSchema = yup.object().shape({
        Month: yup.string().required("Month  is required"),
        Year: yup.string().required("Year  is required"),
    });
    const submitForm = async (data) => {
        try {
            const isValid = await LeadSchema.validate(data);
            if (isValid) {
                GetLeads(data)
            }
        } catch (error) {
            console.error(error);
        }
    };


    const GetLeads = async (data) => {
        try {
            const response = await get_LeadMonthlyAndYearly_Data({
                Month: data?.Month,
                Year: data?.Year
            });

            if (Red_LeadMonthAndYear?.data?.[0]?.res.success === "success") {
                messageApi.success("loading Leads");
                setTimeout(() => {

                }, 3000);
            } else {
                const errorMessage = response?.messsage || 'loading Leads';
                messageApi.error(errorMessage);
            }
        } catch (error) {
            console.error("Error occurred loading  Leads :", error);
            messageApi.error("An error occurred while loading  Leads");
        }
    };



    const columns = [
        {
            title: "Cutomer Name",
            dataIndex: "cutomer_name",
            key: "cutomer_name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Number",
            dataIndex: "number",
            key: "number",
        },
        {
            title: "Agent Name",
            dataIndex: "agent_name",
            key: "agent_name",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
        },
        // {
        //     title: "Action",
        //     key: "action",
        //     render: (data) => (
        //         <Space size="middle">
        //             <button
        //                 onClick={() => EditPage("Edit", data?.City_code)}
        //                 className="editBtn"
        //             >
        //                 <FaEdit />
        //             </button>

        //             <Popconfirm
        //                 title="Delete the Cost Centre"
        //                 description="Are you sure to delete the Base City?"
        //                 okText="Yes"
        //                 cancelText="No"
        //                 onConfirm={() => {
        //                     // handleConfirmDelete(data?.City_code)
        //                 }}
        //             >
        //                 <button className="deleteBtn"><MdDeleteOutline /></button>
        //             </Popconfirm>

        //         </Space>
        //     ),
        // },
    ];


    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {},
        mode: "onChange",
        resolver: yupResolver(LeadSchema),
    });

    //  useEffect(() => {
    //      get_LeadMonthlyAndYearly_Data()
    //  }, [])

    console.log(Red_LeadMonthAndYear?.data?.[0]?.res, 'Red_LeadMonthAndYear')

    return (
        <>

            {contextHolder}
            <div className="container ">
                <div className="row">
                    <div className="col-lg-12 maringClass2">
                        <div className="row d-flex justify-content-end">
                            <form onSubmit={handleSubmit(submitForm)} className="col-4 d-flex justify-content-end pl-2">
                                <FormSelect
                                    placeholder={'Year  '}
                                    id="Year"
                                    name="Year"
                                    options={[
                                        { id: 2022, name: '2022' },
                                        { id: 2023, name: '2023' },
                                        { id: 2024, name: '2024' },
                                    ]}
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <FormSelect
                                    placeholder={'Month  '}
                                    id="Month"
                                    name="Month"
                                    options={[
                                        { id: 1, name: 'January' },
                                        { id: 2, name: 'Feburary' },
                                        { id: 3, name: 'March' },
                                        { id: 4, name: 'April' },
                                        { id: 5, name: 'May' },
                                        { id: 6, name: 'June' },
                                        { id: 7, name: 'July' },
                                        { id: 8, name: 'August' },
                                        { id: 9, name: 'September' },
                                        { id: 10, name: 'October' },
                                        { id: 11, name: 'November' },
                                        { id: 12, name: 'December' },
                                    ]}
                                    showLabel={true}
                                    errors={errors}
                                    control={control}
                                />
                                <PrimaryButton type="submit" title={'submit'} />
                            </form>

                        </div>
                        <div>

                            <Table
                                columns={columns}
                                loading={false}
                                dataSource={Red_LeadMonthAndYear?.data?.[0]?.res?.data}
                                scroll={{ x: 10 }}
                            // pagination={{
                            //     defaultCurrent: page,
                            //     total: Red_LeadMonthAndYear?.data?.[0]?.res?.data3,
                            //     onChange: (p) => {
                            //         setPage(p);
                            //     },
                            //     pageSize: pageSize,
                            // }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
function mapStateToProps({ Red_LeadMonthAndYear }) {
    return { Red_LeadMonthAndYear };
}

export default connect(mapStateToProps, Leads_Actions)(LeadByMonthYear);
